<template>
  
  <div class="menu">

    <ul class="menu-list">
      
      <li
        v-for="(item, i) in menu"
        class="menu-item"
        @click="changeClass(i)"
        :class="{active: isActive === i}"
        :key="i">

          <router-link class="routerlink" :to="item.path">{{ item.label }}
          </router-link>
      </li>

    </ul>

  </div>

</template>

<script>
export default {
  name: 'app-menu',
  data () {
    return {
      isActive:'',
      menu: [
        {
          label: '巡查上报',
          path: '/admin/patrol'
        },
        {
          label: '执法管理',
          path: '/admin/lawmanagement'
        },
        {
          label: '政策法规',
          path: '/admin/law'
        },
        {
          label: '对比分析',
          path: '/admin/contrastive-analysis'
        },
        // {
        //   label: '编辑新闻',
        //   path: '/admin/ckeditor4'
        // },
      ]
    }
  },
  watch:{
    $route(to,from){
      if (to.name === 'map') {
        this.isActive = ''
        
      }
    }
  },
  mounted() {
    (this.$route.path);

  },
  methods: {
    changeClass(i){
      this.isActive = i;
    }
  },
}
</script>

<style scoped lang='scss'>
.menu {
  display: none;
  flex-shrink: 0;
  height: 100%;
  width: 200px;
  margin-bottom:20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background:url('../../assets/menubg.png') #0061be no-repeat bottom;
  background-size: 100% auto;
}
.menu-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  height: 100%;
  padding-left: 0;
  width: 100%;
}
.menu-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  /* margin-bottom: 10px; */
  width: 100%;
  /* background:#3073CC */
  .routerlink{
    height: 100%; 
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center
}
}

.active{
  background:url('../../assets/menuli.png') #053f77
}
a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
</style>
