<template>
  <div class="homepage-nav">
    <router-link to="/admin/map">
      <el-button type="primary" size="mini">
        首页
        <i class="iconfont "></i>
      </el-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name :'homepage-nav',
  methods: {

  }
}
</script>

<style scoped>
  .homepage-nav{
    height:32px;
    width: 100% ;
    background: #0f5ba4;
    display: flex;
    position: fixed; 
    top: 60px;
    display: none;

}
.indexbtn{
  margin-left: 15px;
}
</style>
