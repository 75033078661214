<template>
  
  <div class="container">

    <app-header>
    </app-header>



    <div class="content">

      <app-menu>
      </app-menu>

      <div class="sub-content">


        <app-homepage>
        </app-homepage>

        <div class="content-wrapper">

          <keep-alive>
            <router-view></router-view>
          </keep-alive>

        </div>

      </div>

    </div>

  </div>
  
</template>

<script>
import appHeader from './header'
import appMenu from './menu'
import appHomePage from './homepagenav'
export default {
  name: 'container',
  components: {
    'app-header': appHeader,
    'app-menu': appMenu,
    'app-homepage':appHomePage
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  position: relative
}
.sub-content {
  z-index: 10;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  /* height: calc(100% - 35px); */
  overflow: hidden;
  position: relative;
  /* padding-top: 35px; */
    /* pointer-events: none; */
}
.content-wrapper {
  height: 100%;
  /* pointer-events: none; */
  width: 100%;
  position: absolute;
  /* top: 32px; */
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10
}
</style>
