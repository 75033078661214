<template>

  <div class="header">
    <div class="header-nav">
    </div>
  </div>

</template>

<script>
export default {
  name: 'app-header'
}
</script>

<style scoped>
.header {
  pointer-events: none;
  /* display: none; */
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    background: url('./map/img/headerbg.png') center no-repeat;
  width: 3840px;
  height:98px;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  display: flex;
  justify-content: center;
}
.header-nav{
  width: 620px;
  height:57px;
  display:flex;
  background: url('./map/img/header_text.png') center no-repeat ;
  padding-top: 11px;
}
.header-left{
  width: 100%;
}
/* .header-right{
  width: 30%;
} */
.header-span{
  color: azure;
  font-size: 36px;
  height: 60px;
  width: 100%;
  text-align: center;
  line-height: 60px;
}

</style>
